




















import {Component, Prop, Vue} from "vue-property-decorator";
import MontageService from "@/views/offer/montage.service";

@Component({
  components: {
  }
})
export default class MontageTableRowComponent extends Vue {
  @Prop({ default: () => {} })
  public montage!: any;

  @Prop({ })
  public index!: number;

  /*private montageService: any;*/

  public created() {
    /*this.montageService = MontageService();*/
  }

  pos: number = 2

  private posRechner(index: number) {
    let m = (index === 0) ? 0.1 : (index + 1) / 10;
    return this.pos + m
  }
}
